<template>
  <div class="lead-item-theme" :style="{ backgroundImage: `url(${item.headImg?.[0]})` }"
    @click="handleClick">
    <div class="lead-item-content-row">
      <h3 class="lead-item-title">
        {{ item.title }}
      </h3>
      <div class="lead-item-content">
        <div class="lead-item-author-row">
          <div class="lead-item-channel">
            {{ item.channel }}
          </div>
        </div>

        <div class="lead-item-date">
          {{ $global.formatDate($global.dateStringToTimestamp(item.published),'MMM dd, yyyy hh:mm') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../../css/module/lead/theme_5.scss';

export default {
  props: {
    item: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
